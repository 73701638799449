import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import AppHeader from 'organisms/AppHeader';
import Drawer from 'molecules/Drawer';
import Footer from 'organisms/Footer';
import ContainerWithBackground from 'molecules/ContainerWithBackground';

import SideDrawer from 'containers/SideDrawer';

import colors from 'assets/themes/colors';

import { navigate } from '@reach/router';

import assets from 'assets/themes/variants/assets';

const Wrapper = styled.div`
  position: relative;
  flex: 1;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.lightGray};
`;

const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
`;

const DrawerContent = styled.div`
  padding-top: 80px;
  height: 100%;
`;

const Content = styled.div`
  width: 100%;
  min-height: 100vh;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  background-color: ${colors.lightGray};
`;

// eslint-disable-next-line react/prop-types
export const Container = ({ children, backgroundSource }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 80px)',
      }}>
      <ContainerWithBackground
        source={assets[backgroundSource] || String(backgroundSource)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          height: 'auto',
        }}>
        {children}
      </ContainerWithBackground>
    </div>
  );
};

const AppContainer = ({ children }) => {
  const [drawerIsOpen, toggleDrawer] = useState(false);
  const [headerConfig, setHeader] = useState({});

  const headerProps = {
    ...headerConfig,
    onClickAvatar: () => toggleDrawer(!drawerIsOpen),
  };

  const handleSetHeader = object => {
    if (!object) return;
    PropTypes.checkPropTypes(
      {
        renderLeft: PropTypes.element,
        renderCenter: PropTypes.element,
        title: PropTypes.string,
        showBackButton: PropTypes.bool,
      },
      object,
      'key',
      'setHeader'
    );
    setHeader(object);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [children]);

  return (
    <AppContainerContext.Provider
      value={{
        toggleDrawer,
        setHeader: handleSetHeader,
      }}>
      <Wrapper>
        <Header>
          <AppHeader drawerIsOpen={drawerIsOpen} {...headerProps} />
        </Header>

        <Drawer
          onClickToHide={() => toggleDrawer(false)}
          onHide={() => toggleDrawer(false)}
          isOpen={drawerIsOpen}>
          {drawerProps => (
            <DrawerContent>
              <SideDrawer {...drawerProps} />
            </DrawerContent>
          )}
        </Drawer>

        <Content>{children}</Content>

        <Footer
          links={[
            {
              name: 'Privacy Policy',
              onClick: () => navigate('/privacy'),
            },
            {
              name: 'Terms and Conditions',
              onClick: () => navigate('/terms'),
            },
            {
              name: 'Refund Policies',
              onClick: () => navigate('/refunds'),
            },
            {
              name: 'Responsible Play',
              onClick: () => navigate('/responsible-play'),
            },
            {
              name: 'How to Play',
              onClick: () => navigate('/how-to-play'),
            },
            {
              name: 'Contact Us',
              onClick: () => navigate('/contact'),
            },
            {
              name: 'About Us',
              onClick: () => navigate('/about'),
            },
          ]}
        />
      </Wrapper>
    </AppContainerContext.Provider>
  );
};

AppContainer.propTypes = {
  children: PropTypes.any,
};

export { default as useAppContainer } from './useAppContainer';

export const AppContainerContext = React.createContext({});

export default AppContainer;
