import React from 'react';
import PropTypes from 'prop-types';

import colors from 'assets/themes/colors';

import { Button, ImageWrapper, Title } from 'atoms';

import NFLLogo from 'assets/media/logos/nfl/NFL.svg';

import { useAppContainer, Container } from 'organisms/AppContainer';
import { navigate } from '@reach/router';

const Wrapper = ({ children }) => {
  useAppContainer({
    header: {
      renderLeft: (
        <Button isBare onClick={() => navigate('/gameplay')}>
          <ImageWrapper source={NFLLogo} alt={'NFL Logo'} height={42} />
        </Button>
      ),
      renderCenter: (
        <Title isSmall color={colors.white}>
          Submitting a Contest
        </Title>
      ),
    },
  });

  return <Container backgroundSource="submitGate">{children}</Container>;
};

Wrapper.propTypes = {
  children: PropTypes.any,
};

export default Wrapper;
