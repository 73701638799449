import { camelCaseToSpace } from 'services/utilities';

export const defaultMetrics = {
  QB: ['passingYards', 'touchdowns', 'interceptions'],
  RB: [
    'rushingYardsPerAttempt',
    'rushingAttempts',
    'touchdowns',
    'rushingYards',
  ],
  WR: ['receivingYards', 'receptions', 'touchdowns'],
  TE: ['receivingYards', 'receptions', 'touchdowns'],
  K: ['extraPointsMade', 'fieldGoalsMade'],
  OLB: ['soloTackles', 'assistedTackles'],
  ILB: ['soloTackles', 'assistedTackles'],
  DE: ['soloTackles', 'assistedTackles', 'quarterbackHits'],

  // NBA
  C: ['fieldGoalsMade', 'fieldGoalsAttempted', 'twoPointersMade', 'twoPointersAttempted', 'threePointersMade', 'threePointersAttempted', 'freeThrowsMade', 'freeThrowsAttempted', 'offensiveRebounds', 'defensiveRebounds', 'rebounds', 'assists', 'steals', 'blockedShots', 'turnovers', 'personalFouls', 'points'],
  PF: ['fieldGoalsMade', 'fieldGoalsAttempted', 'twoPointersMade', 'twoPointersAttempted', 'threePointersMade', 'threePointersAttempted', 'freeThrowsMade', 'freeThrowsAttempted', 'offensiveRebounds', 'defensiveRebounds', 'rebounds', 'assists', 'steals', 'blockedShots', 'turnovers', 'personalFouls', 'points'],
  PG: ['fieldGoalsMade', 'fieldGoalsAttempted', 'twoPointersMade', 'twoPointersAttempted', 'threePointersMade', 'threePointersAttempted', 'freeThrowsMade', 'freeThrowsAttempted', 'offensiveRebounds', 'defensiveRebounds', 'rebounds', 'assists', 'steals', 'blockedShots', 'turnovers', 'personalFouls', 'points'],
  SF: ['fieldGoalsMade', 'fieldGoalsAttempted', 'twoPointersMade', 'twoPointersAttempted', 'threePointersMade', 'threePointersAttempted', 'freeThrowsMade', 'freeThrowsAttempted', 'offensiveRebounds', 'defensiveRebounds', 'rebounds', 'assists', 'steals', 'blockedShots', 'turnovers', 'personalFouls', 'points'],
  SG: ['fieldGoalsMade', 'fieldGoalsAttempted', 'twoPointersMade', 'twoPointersAttempted', 'threePointersMade', 'threePointersAttempted', 'freeThrowsMade', 'freeThrowsAttempted', 'offensiveRebounds', 'defensiveRebounds', 'rebounds', 'assists', 'steals', 'blockedShots', 'turnovers', 'personalFouls', 'points']

};

export default function formatMetrics(metrics, position) {
  if (!metrics) {
    return [];
  }
  return Object.keys(metrics)
    .map(metricKey => {
      if (defaultMetrics[position] && defaultMetrics[position].includes(metricKey)) {
        return {
          name: metricKey,
          label: camelCaseToSpace(metricKey, true),
          value: metrics[metricKey],
        };
      // } else {
        // return {
        //   name: '',
        //   label: '',
        //   value: null,
        // };
      }
    })
    .filter(i => i != undefined);
}
