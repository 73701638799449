import React from 'react';
import PropTypes from 'prop-types';

import colors from 'assets/themes/colors';

import { Button, ImageWrapper, Title } from 'atoms';

import NFLLogo from 'assets/media/logos/nfl/NFL.svg';

import { useAppContainer } from 'organisms/AppContainer';
import { navigate } from '@reach/router';

const Wrapper = ({ children }) => {
  useAppContainer({
    header: {
      renderLeft: (
        <Button isBare onClick={() => navigate('/gametype')}>
          <ImageWrapper source={NFLLogo} alt={'NFL Logo'} height={42} />
        </Button>
      ),
      renderCenter: (
        <Title isSmall color={colors.white}>
          Select a Type of Game
        </Title>
      ),
    },
  });

  return <div style={{ height: 'calc(100vh - 80px)' }}>{children}</div>;
};

Wrapper.propTypes = {
  children: PropTypes.any,
};

export default Wrapper;
