import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import colors from 'assets/themes/colors';
import { Button, Group, ImageWrapper, Title } from 'atoms';
import { useAppContainer } from 'organisms';

import NFLLogo from 'assets/media/logos/nfl/NFL.svg';

const Wrapper = ({ children }) => {
  useAppContainer({
    header: {
      renderLeft: (
        <Group isVerticalCentered spaceSize={6}>
          <Button isBare onClick={() => navigate('/')}>
            <ImageWrapper source={NFLLogo} alt={'NFL Logo'} height={42} />
          </Button>
        </Group>
      ),
      renderCenter: (
        <Title isSmall color={colors.white}>
          My Account
        </Title>
      ),
    },
  });
  return children;
};

Wrapper.propTypes = {
  children: PropTypes.node,
};

export default Wrapper;
