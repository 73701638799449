import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Group, { Fill } from 'atoms/Group';
import GeneralText from 'atoms/GeneralText';
import colors from 'assets/themes/colors';

const PlaceholderSpace = styled.div`
  flex-grow: 1;
  width: 100%;
`;

const HeaderContainer = ({
  leftComponent,
  centerComponent,
  rightComponent,
  padding,
}) => {
  return (
    <Group
      isFullWidth
      isCentered
      isVertical
      padding={padding}
      style={{
        backgroundColor: colors.primary,
        height: 80,
        width: '100%',
      }}>
      <PlaceholderSpace />
      <Group
        isFullWidth
        isCentered
        isVerticalCentered
        spaceSize={0}
        css={{ height: '50px', justifyContent: 'flex-start' }}>
        <div
          data-guided-tour="header-left-component"
          style={{ width: '60px', textAlign: 'left' }}>
          {leftComponent}
        </div>
        <Fill style={{ textAlign: 'center' }}>
          {typeof centerComponent === 'string' ? (
            <GeneralText isMedium color={colors.white}>
              <strong>{centerComponent}</strong>
            </GeneralText>
          ) : (
            centerComponent
          )}
        </Fill>
        <div style={{ width: '60px', textAlign: 'right' }}>
          <div
            data-guided-tour-emulate-click="header-right-component"
            data-guided-tour="header-right-component"
            style={{ display: 'inline-block' }}>
            {rightComponent}
          </div>
        </div>
      </Group>
    </Group>
  );
};

HeaderContainer.propTypes = {
  leftComponent: PropTypes.element,
  centerComponent: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.string.isRequired,
  ]),
  rightComponent: PropTypes.element,
  componentSpacing: PropTypes.number,
  padding: PropTypes.string,
  isGrownLeft: PropTypes.bool,
  isGrownCenter: PropTypes.bool,
  isGrownRight: PropTypes.bool,
};

HeaderContainer.defaultProps = {
  padding: '0 15px 15px 15px',
  isGrownLeft: false,
  isGrownCenter: true,
  isGrownRight: false,
};

export default HeaderContainer;
