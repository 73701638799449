import React from 'react';
import PropTypes from 'prop-types';

import { Heading } from 'rebass';

import colors, { invertColor } from 'assets/themes/colors';

const Title = ({
  children,
  color,
  colorByBackground,
  isLarge,
  isMedium,
  isSmall,
}) => {
  let variant = 'regular';
  if (isSmall) variant = 'small';
  if (isMedium) variant = 'medium';
  if (isLarge) variant = 'large';

  return (
    <Heading
      variant={`heading.${variant}`}
      color={colorByBackground ? invertColor(colorByBackground) : color}
      sx={{ lineHeight: 1 }}>
      {children}
    </Heading>
  );
};

Title.defaultProps = {
  color: colors.primary,
};

Title.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  colorByBackground: PropTypes.string,
  isLarge: PropTypes.bool,
  isMedium: PropTypes.bool,
  isSmall: PropTypes.bool,
};

export default Title;
