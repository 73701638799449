import React, { useState } from 'react';
import PropTypes from 'prop-types';

import colors from 'assets/themes/colors';
import { GeneralText, Space } from 'atoms';
import { StatsRow } from 'molecules';
import CardWrapper from 'molecules/CardWrapper';
import PlayerAvatar from 'molecules/PlayerAvatar';
import PersonHeading from 'molecules/PersonHeading';

import Modal from 'molecules/Modal';
import ModalHeader from 'organisms/ModalHeader';

// import AnimatedBackground from './AnimatedBackground';
import PlayerProfile from 'pages/PlayerProfile';

const PlayerCard = props => {
  const {
    id,
    children,
    cardDescription,
    cardLabel,
    showPlayerInfo,
    status,
    fullName,
    picture,
    position,
    seasonAverage,
    isActive,
  } = props;

  const [showDetails, setDetailsState] = useState(false);

  return (
    <CardWrapper
      specSelector={`player-card-${isActive ? 'active' : 'inactive'}`}
      topLabel={<GeneralText color={colors.white}>{cardLabel}</GeneralText>}
      sx={{ paddingBottom: 25, height: 460 }}>
      {isActive && (
        <>
          <div style={{ position: 'relative', zIndex: 1, textAlign: 'center' }}>
            <StatsRow stats={seasonAverage || []} hasLabelOnTop />

            <PlayerAvatar
              source={isActive && picture}
              flagLabel={status}
              hasInfo={showPlayerInfo}
              onClick={() => setDetailsState(true)}
            />

            <Modal
              isOpen={showDetails}
              customHeader={() => (
                <ModalHeader
                  onClickToClose={() => setDetailsState(false)}
                  title={fullName}
                />
              )}
              onClickToClose={() => setDetailsState(false)}>
              {showDetails && (
                <PlayerProfile
                  competitorId={id}
                  seasonAverage={seasonAverage}
                />
              )}
            </Modal>

            <Space isVertical size={20} />

            <PersonHeading subtitle={position}>{fullName}</PersonHeading>

            <Space isVertical size={5} />

            {children}

            {/* <Space isVertical size={5} /> */}

            <GeneralText>
              <b>{cardDescription}</b>
            </GeneralText>

            <Space isVertical size={30} />
          </div>

          {/* <AnimatedBackground /> */}
        </>
      )}
    </CardWrapper>
  );
};

PlayerCard.defaultProps = {
  cardDescription: '---',
  cardLabel: '---',
  fullName: '---',
  position: '---',
  stats: [],
  showPlayerInfo: true,
};

PlayerCard.propTypes = {
  id: PropTypes.string,
  cardDescription: PropTypes.string,
  cardLabel: PropTypes.string,
  children: PropTypes.any,
  fullName: PropTypes.string,
  isActive: PropTypes.bool,
  picture: PropTypes.string,
  position: PropTypes.string,
  seasonAverage: PropTypes.array,
  showPlayerInfo: PropTypes.bool,
  status: PropTypes.string,
  labelTop: PropTypes.bool,
  labelColor: PropTypes.string,
  labelSize: PropTypes.oneOf(['isLarge', 'isMedium', 'isSmall', 'isRegular']),
};

export default PlayerCard;
