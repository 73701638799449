/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

export default {
  name: 'gameTypeSelectionTour',
  steps: [
    {
      target: 'body',
      title: 'Welcome!',
      content: (
        <div>
          Ready for a quick tour of Meteor Fantasy? <br />
          <br />
          NOTE: If you no longer want to see the tour, just click on <b>SKIP</b>
          . After clicking on SKIP, you will no longer see the tour. You will
          need to do this for each of the tours on the app.
        </div>
      ),
      placement: 'center',
    },
    {
      target: '[data-guided-tour="header-left-component"]',
      title: 'NFL is just the start...',
      content: 'More sports will be launching soon! ',
    },
    {
      target: '[data-guided-tour="game-type"]',
      title: 'Contest Types',
      content: (
        <div>
          Where do you want to test your prediction skills? Over/Under? Or
          StatPicks? With StatPicks, you can test your accuracy at predicting
          your favorite player’s performance this weekend. Paid games are coming
          soon, but you can earn Meteor Dollars, our promotional currency, with
          our free contests that you can use in our paid contests when they go
          live!
        </div>
      ),
    },
    {
      target: '[data-guided-tour="select-free-contest"]',
      title: 'Free vs Paid',
      content:
        'Here! This is where those Free contests we were just talking about are!',
    },
    {
      target: '[data-guided-tour="links"]',
      title: 'Learn How to Play and more...',
      content:
        'Head to "How to Play" in our links section to get all the rules!',
    },
  ],
  variant: [
    {
      target: 'body',
      title: 'Welcome to Meteor Fantasy!',
      content: (
        <>
          You can learn more about any aspect of Meteor Fantasy game play by
          just clicking on these beacons and an explanation will show. Good
          Luck!
          <video
            autoPlay
            loop
            style={{ width: '100%', height: 200, marginTop: 10 }}>
            <source
              src={require('assets/videos/beacon-demo.mp4')}
              type="video/mp4"
            />
          </video>
        </>
      ),
      placement: 'center',
    },
    {
      target: '[data-guided-tour="game-type"]',
      title: 'Contest Types',
      content:
        'Paid games are coming soon, but you can earn Meteor Dollars, our promotional currency, with our free contests that you can use in our paid contests when they go live!',
      placement: 'auto',
    },
  ],
};
