import React from 'react';
import PropTypes from 'prop-types';

import { Group, ImageWrapper } from 'atoms';

import NFLLogo from 'assets/media/logos/nfl/NFL.svg';

import { useAppContainer, Container } from 'organisms/AppContainer';

const Wrapper = ({ children }) => {
  useAppContainer({
    header: {
      renderLeft: (
        <Group isVerticalCentered spaceSize={6}>
          <ImageWrapper source={NFLLogo} alt={'NFL Logo'} height={42} />
        </Group>
      ),
      renderCenter: 'Final Step',
    },
  });

  return <Container backgroundSource="submitGate">{children}</Container>;
};

Wrapper.propTypes = {
  children: PropTypes.any,
};

export default Wrapper;
