import { useEffect, useRef, useState } from 'react';

import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import EventEmitter from 'eventemitter3';

const db = firebase.firestore;
const auth = firebase.auth;

const emitter = new EventEmitter();

let holdUserData = null;

let unsubscribeDb = null;
let dbIsOpen = false;

const onDbUpdate = () =>
  db()
    .collection(process.env.firestoreUsers)
    .doc(auth().currentUser.uid)
    .onSnapshot(snapshot => {
      dbIsOpen = true;
      holdUserData = snapshot.data();
      emitter.emit('user-changes', snapshot.data());
    });

const useUserData = () => {
  const [currentUser, updateCurrentUser] = useState(holdUserData);
  const [status, setStatus] = useState('waiting');

  const unlisten = useRef();

  const updateUser = (response = {}) => {
    const userObject = {
      ...holdUserData,
      ...response,
      ...auth().currentUser.toJSON(),
      isAnonymous: false,
    };
    holdUserData = userObject;
    updateCurrentUser(userObject);
    localStorage.setItem('userLastUpdate', Date.now());
    setStatus('success');
  };

  const reload = () => {
    return new Promise((resolve, reject) => {
      if (auth() && auth().currentUser) {
        auth()
          .currentUser.reload()
          .then(() => {
            emitter.emit('user-changes');
            resolve();
          })
          .catch(reject);
      }
    });
  };

  useEffect(() => {
    setStatus('loading');

    emitter.on('user-changes', updateUser);

    unlisten.current = auth().onAuthStateChanged(currentState => {
      if (!currentState) {
        if (dbIsOpen) {
          setStatus('waiting');
          updateCurrentUser(null);
          unsubscribeDb();
          holdUserData = null;
          dbIsOpen = false;
        } else {
          setStatus('success');
        }
      }
      if (currentState) {
        if (!dbIsOpen) {
          unsubscribeDb = onDbUpdate();
        }
        if (!currentUser) updateUser();
      }
    });

    return () => {
      emitter.off('user-changes', updateUser);
      if (unlisten.current) unlisten.current();
    };
  }, []);

  return [
    {
      isAnonymous: true,
      ...currentUser,
      status,
      isReady: dbIsOpen,
    },
    reload,
  ];
};

export default useUserData;
