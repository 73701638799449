import React from 'react';
import PropTypes from 'prop-types';

import TeamSlider from 'molecules/TeamSlider';

const TeamsSelector = ({ listedTeams, onSelect, selection }) => {
  const normalizedData = [...listedTeams].map(item => {
    const isHome = item.homeTeam;
    let currentIndex = 0;
    const teamToCompare = isHome ? item.homeTeamName : item.awayTeamName;
    const isSelected = selection.filter((s, i) => {
      if (s.teamName === item.teamName) {
        currentIndex = i;
        return true;
      }
    }).length;
    const isExcluded = selection.filter(s => {
      if (isHome) return s.teamName === item.awayTeamName;
      if (!isHome) return s.teamName === item.homeTeamName;
    }).length;
    return {
      source: isHome ? item.homeTeamLogoUrl : item.awayTeamLogoUrl,
      isSelected: isSelected > 0,
      isExcluded: isExcluded > 0,
      name: teamToCompare,
      number: currentIndex + 1,
    };
  });
  return (
    <div style={{ maxWidth: 445, margin: 'auto' }}>
      <TeamSlider teams={normalizedData} onSelect={onSelect} />
    </div>
  );
};

TeamsSelector.defaultProps = {
  listedTeams: [],
};

TeamsSelector.propTypes = {
  listedTeams: PropTypes.array,
  onSelect: PropTypes.func,
  selection: PropTypes.array,
};

export default TeamsSelector;
