import React from 'react';
import PropTypes from 'prop-types';

import HeaderContainer from 'molecules/HeaderContainer';

import { Avatar, Button, Icon, Group, GeneralText } from 'atoms';
import { EllipsisV } from 'assets/icons';

import { gravatar } from 'services/utilities';
import useUserData from 'hooks/useUserData';

import assets from 'assets/themes/variants/assets';
import colors from 'assets/themes/colors';

const AppHeader = ({
  title,
  renderLeft,
  renderCenter,
  onClickAvatar,
  drawerIsOpen,
}) => {
  const [session] = useUserData();

  const isAnonymous = session && session.isAnonymous;
  const email = session && session.email;
  const photoURL = session.photoURL;

  const anonymousPlaceholder = assets['anonymousAvatarPlaceholder'];
  const avatarPlaceholder = assets['avatarPlaceholder'];

  let avatarSource =
    photoURL || (email && (avatarPlaceholder || gravatar(email)));

  if (isAnonymous) avatarSource = anonymousPlaceholder;

  return (
    <HeaderContainer
      leftComponent={renderLeft}
      centerComponent={
        title ? (
          <GeneralText color="#FFFFFF">{title}</GeneralText>
        ) : (
          renderCenter
        )
      }
      rightComponent={
        <Button specSelector="avatar-header" isBare onClick={onClickAvatar}>
          <Group isVerticalCentered spaceSize={5}>
            <Avatar
              size={38}
              style={{ border: 'none' }}
              source={
                !drawerIsOpen
                  ? avatarSource
                  : 'https://icon-library.net/images/svg-close-icon/svg-close-icon-27.jpg'
              }
            />
            <Icon size={22} color={colors.white}>
              <EllipsisV />
            </Icon>
          </Group>
        </Button>
      }
    />
  );
};

AppHeader.propTypes = {
  title: PropTypes.string,
  renderLeft: PropTypes.any,
  renderCenter: PropTypes.any,
  onClickAvatar: PropTypes.func,
  drawerIsOpen: PropTypes.bool,
  showBackButton: PropTypes.bool,
};

export default AppHeader;
