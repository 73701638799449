/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import { GeneralText, Space, Group } from 'atoms';
import { StatsRow } from 'molecules';
import { BasicTextCard, PlayerProfileHeader } from 'organisms';

import { positions } from 'services/friendlyNames';
import formatMetrics from 'services/formatMetrics';

import useCompetitorData from './useCompetitorData';

const PlayerProfile = ({ competitorId, seasonAverage }) => {
  // TODO: As a page (similar to a container, this component should not receive this amount of data to handle by it self).
  // We have to wrap it with another "container like" component or receive a payload and drill down to its children

  const { response, status } = useCompetitorData(competitorId);

  if (!response) return null; // TODO: Change for a nice loading component

  const weeks = Object.keys(response.seasonResults || {})
    .map(w => Number(w.split('week')[1]))
    .sort((a, b) => a - b)
    .reverse();

  return (
    <>
      <PlayerProfileHeader
        teamName={response.teamName}
        fullName={`${response.firstName} ${response.lastName}`}
        teamLogoUrl={null}
        picture={response.headshotUrl}
        position={positions[response.position]}
        playerNumber={null}
      />

      <Space isVertical isSmall />

      <StatsRow stats={seasonAverage || []} />

      <Space isVertical isSmall />

      <Group isVertical isCentered>
        <BasicTextCard
          title="Profile"
          data={[
            {
              label: 'Birthdate',
              value: response.birthDate,
            },
            {
              label: 'Experience',
              value: response.experience,
            },
            {
              label: 'College',
              value: response.college,
            },
            {
              label: 'Height',
              value: response.height,
            },
          ]}
        />

        <Space isVertical isMedium />

        <GeneralText>
          <b>Current Season Performance</b>
        </GeneralText>

        <Space isVertical />
        <Group isFullWidth isVertical spaceSize={10}>
          {weeks.map((week, index) => {
            const title = `Week ${week}`;
            const data = response.seasonResults[`week${week}`];
            return (
              <BasicTextCard
                key={week}
                title={title}
                isAnimated={false}
                data={formatMetrics(data, response.position)}
              />
            );
          })}
        </Group>
      </Group>
    </>
  );
};

PlayerProfile.propTypes = {
  competitorId: PropTypes.string.isRequired,
  seasonAverage: PropTypes.array,
};

export default PlayerProfile;
