import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Formik, FieldArray } from 'formik';

const timeout = setTimeout;

// Set initial focus to the first input field
function setFormInitialFocus(formElement) {
  const form = formElement;
  if (form) {
    const formElements = form.elements;
    for (let i = 0; i < formElements.length; i++) {
      if (formElements[i].nodeName !== 'BUTTON') {
        timeout(() => formElements[i].focus(), 100);
        break;
      }
    }
  }
}

export const FieldRepeater = FieldArray;

export const Form = ({ children, hasAutoFocus, ...rest }) => {
  const formRef = useRef();

  useEffect(() => {
    setInitialFocus();
    return () => clearTimeout(timeout);
  }, []);

  function setInitialFocus() {
    if (hasAutoFocus) setFormInitialFocus(formRef.current);
  }

  return (
    <form {...rest} ref={formRef}>
      {children}
    </form>
  );
};

Form.defaultProps = {
  hasAutoFocus: false,
};

Form.propTypes = {
  children: PropTypes.element.isRequired,
  hasAutoFocus: PropTypes.bool,
};

const FormContainer = ({ hasAutoFocus, onSubmit, render, ...rest }) => (
  <Formik
    initialValues={{}}
    {...rest}
    onSubmit={onSubmit}
    render={renderProps => (
      <Form
        hasAutoFocus={hasAutoFocus}
        onReset={renderProps.handleReset}
        style={{ width: '100%' }}
        onSubmit={event => event.preventDefault()}>
        {render(renderProps)}
      </Form>
    )}
  />
);

FormContainer.defaultProps = {
  hasAutoFocus: false,
  onSubmit: null,
  render: null,
};

FormContainer.propTypes = {
  hasAutoFocus: PropTypes.bool,
  onSubmit: PropTypes.func,
  render: PropTypes.func,
};

export default FormContainer;
