import React from 'react';
import PropTypes from 'prop-types';

import { Button, Icon } from 'atoms';
import { Times } from 'assets/icons';

import HeaderContainer from 'molecules/HeaderContainer';

import colors from 'assets/themes/colors';

const ModalHeader = ({ onClickToClose, title }) => {
  return (
    <HeaderContainer
      leftComponent={
        !!onClickToClose && (
          <Button isBare onClick={onClickToClose}>
            <Icon size={18} color={colors.white}>
              <Times />
            </Icon>
          </Button>
        )
      }
      centerComponent={title}
    />
  );
};

ModalHeader.propTypes = {
  onClickToClose: PropTypes.func,
  title: PropTypes.string,
};

export default ModalHeader;
