import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'teamsSelection',
  storage,
  blacklist: ['isLoading', 'error', 'availableTeams'],
};

const INITIAL_STATE = {
  availableTeams: {},
  selectedDayDivisor: null,
  dayDivisors: [],
  teams: null,
  competitors: null,
  timeBlockId: null,
  isLoading: null,
  error: null,
};

export default persistReducer(
  persistConfig,
  (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
      case 'FETCH_AVAILABLE_TEAMS':
        return (state = { ...state, isLoading: true });

      case 'FETCH_AVAILABLE_TEAMS_SUCCESS':
        return (state = {
          ...state,
          isLoading: false,
          availableTeams: action.payload,
        });

      case 'FETCH_AVAILABLE_TEAMS_ERROR':
        return (state = {
          ...state,
          isLoading: false,
          error: action.payload,
        });

      case 'SET_DAY_DIVISORS':
        return (state = { ...state, dayDivisors: action.payload });

      case 'UPDATE_SELECTED_TEAMS':
        return (state = { ...state, ...action.payload });

      case 'RESET_GAME':
        return (state = {
          ...state,
          teams: null,
          competitors: null,
          timeBlockId: null,
        });

      default:
        return state;
    }
  }
);
