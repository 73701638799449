import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { toArrayWithIds } from './utilities';

import moment from 'moment';
import 'moment-timezone';

const db = firebase.firestore;

export default {
  competitionLineups: (filter, callback) =>
    new Promise((resolve, reject) => {
      const { start, end } = filter || {};

      // TODO: Implement others leagues later
      const startDate = moment()
        .subtract(1, 'day')
        .isoWeekday(2)
        .startOf('day')
        .toDate();

      const endDate = moment(startDate)
        .isoWeekday(1)
        .endOf('day')
        .add(1, 'week')
        .toDate();

      // These lines is for debug
      console.log('startDate: ' + (start || startDate));
      console.log('endDate: ' + (end || endDate));

      db()
        .collection(process.env.firestoreCompetitionsLineups)
        .where('timeBlock.start', '>=', start || startDate)
        .where('timeBlock.start', '<=', end || endDate)
        .where('timeBlock.status', '==', 2)
        .onSnapshot(snapshot => {
          // This lines is for debug
          console.log(toArrayWithIds(snapshot));
          return (callback || resolve)(toArrayWithIds(snapshot));
        }, (error) => {
          console.log(error)

        });
    }),
  entryFeesInstances: ({ timeBlockId, gameType, modality }, callback) =>
    new Promise((resolve, reject) => {
      if (!timeBlockId || !gameType || !modality) reject();
      const operator = modality === 'free' ? '==' : '>';
      db()
        .collection(process.env.firestoreEntryFessInstances)
        .where('timeBlockId', '==', timeBlockId)
        .where('gameType', '==', gameType)
        .where('entryFee', operator, 0)
        .onSnapshot(
          snapshot => (callback || resolve)(toArrayWithIds(snapshot)),
          reject
        );
    }),
};
