import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import * as actions from './actions';

import Routes from './Routes';

import checkConnection from 'hooks/checkConnection';

import AppContainer from 'organisms/AppContainer';

import useAnalytics from 'hooks/useAnalytics';
import useRollbar from 'hooks/useRollbar';

// import VerifyEmailFlag from 'molecules/VerifyEmailFlag';

export const MainContext = React.createContext({});

export const MainContainer = ({ resetGame, signOut }) => {
  const isOnline = checkConnection();

  useAnalytics(); // Set tracker instance for the first time
  useRollbar(); // Set rollbar instance for the first time

  useEffect(() => {
    // TODO: Make the RESET more user friendly post MVP
    let version = process.env.npm_package_version;
    let appVersion = localStorage.getItem('app_version');
    if (!appVersion) localStorage.setItem('app_version', version);
    if (appVersion) {
      version = version.split('.');
      appVersion = appVersion.split('.');
      const changes = {
        major: version[0] != appVersion[0],
        minor: version[1] != appVersion[1],
        patch: version[2] != appVersion[2],
      };
      if (changes.major || changes.minor) {
        localStorage.setItem('app_version', version.join('.'));
        signOut();
      }
    }
  }, []);

  return (
    <MainContext.Provider
      value={{
        isOnline,
        resetGame,
        signOut,
      }}>
      <AppContainer>
        <Routes />
      </AppContainer>
      {/* <VerifyEmailFlag /> TODO: Commented for free games */}
    </MainContext.Provider>
  );
};

MainContainer.propTypes = {
  resetGame: PropTypes.func,
  signOut: PropTypes.func,
};

export default connect(
  () => ({}),
  { ...actions }
)(MainContainer);
