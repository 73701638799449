import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { Group, VerticalDivider } from 'atoms';
import { ValueAndLabel } from 'molecules';

const applyStyle = ({ bgColor }) => css`
  background-color: ${bgColor};
`;

const Wrapper = styled(Group)`
  ${applyStyle};
`;

const StatsRow = ({ stats, spaceSize, bgColor, ...rest }) => {
  const statsComponents = [];
  stats.forEach((item, index) => {
    index > 0 &&
      statsComponents.push(
        <VerticalDivider height={50} key={index + 'divider'} />
      );
    statsComponents.push(
      <ValueAndLabel
        value={item.value}
        label={item.label}
        key={index}
        style={{ padding: '0 5px', flex: 1, whiteSpace: 'nowrap' }}
      />
    );
  });
  return (
    <Wrapper
      padding={10}
      isCentered
      isVerticalCentered
      isFullWidth
      spaceSize={spaceSize}
      bgColor={bgColor}
      {...rest}>
      {statsComponents}
    </Wrapper>
  );
};

StatsRow.defaultProps = {
  stats: [],
  spaceSize: 10,
};

StatsRow.propTypes = {
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  spaceSize: PropTypes.number,
  bgColor: PropTypes.string,
};

export default StatsRow;
