import React from 'react';

import { navigate } from '@reach/router';

import Wrapper from './Wrapper';

import colors from 'assets/themes/colors';
import { Button, Group, GeneralText } from 'atoms';

const Success = () => {
  return (
    <Wrapper>
      <Group
        isVertical
        isCentered
        isVerticalCentered
        padding={15}
        spaceSize={60}
        style={{
          width: '100%',
          maxWidth: 450,
          margin: 'auto',
        }}>
        <Group
          isFullWidth
          isVertical
          isCentered
          specSelector="submit-contest-feedback">
          <GeneralText
            color={colors.primary}
            style={{
              fontSize: 36,
              fontWeight: 900,
              padding: '5px 10px',
              backgroundColor: 'white',
              borderRadius: 4,
            }}>
            <em>You&apos;ve Entered!</em>
          </GeneralText>
          {/* TODO: re-enable if we add confirmation emails for entries */}
          {/* <GeneralText
            color={colors.white}
            style={{ fontSize: 14, fontWeight: 900 }}>
            *Check your email for confirmation
          </GeneralText> */}
        </Group>

        <Group isFullWidth isVertical isCentered spaceSize={20}>
          <GeneralText
            color={colors.accent}
            style={{
              fontSize: 22,
              fontWeight: 900,
              padding: '5px 10px',
              backgroundColor: 'white',
              borderRadius: 4,
            }}>
            <em>Now, what&apos;s next?</em>
          </GeneralText>
          <Button
            specSelector="submit-contest-next"
            onClick={() => navigate('/teams')}>
            TRY ANOTHER CONTEST!
          </Button>
          <Button
            isSmall
            isSecondary
            specSelector="submit-contest-see-results"
            onClick={() => navigate('/account/results')}>
            SEE YOUR RESULTS PAGE
          </Button>
        </Group>
      </Group>
    </Wrapper>
  );
};

export default Success;
