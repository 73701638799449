import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import {
  Button,
  Badge,
  GeneralText,
  Space,
  VerticalDivider,
  Group,
  Icon,
} from 'atoms';
import { Fill } from 'atoms/Group';

import { TrophySolid } from 'assets/icons';

import { navigate } from '@reach/router';

import colors from 'assets/themes/colors';
import ImageWrapper from 'atoms/ImageWrapper';
import NFLLogo from 'assets/media/logos/nfl/NFL.svg';

import useUserData from 'hooks/useUserData';

import { MainContext } from 'containers/MainContainer';

import friendlyNames from 'services/friendlyNames';

const CurrentContest = ({ hideDrawer }) => {
  const { resetGame } = useContext(MainContext);

  const { modality, type } = useSelector(state => state.gameType);
  const { predictions, selectedEntryFee } = useSelector(
    state => state.gameplay
  );

  const [session] = useUserData();

  const entries = session.currentEntries || [];

  const { teams } = useSelector(state => state.teamsSelection);

  const currentContestExists =
    entries.filter(c => {
      return (
        selectedEntryFee && c && c.entryFeeInstance.id === selectedEntryFee.id
      );
    }).length > 0;

  const isHome = team => team && team.homeTeam;

  const homeTeam = team => ({
    name: team.teamName,
    logo: isHome(team) ? team.homeTeamLogoUrl : team.awayTeamLogoUrl,
  });

  const awayTeam = team => ({
    name: !isHome ? team.homeTeamName : team.awayTeamName,
    logo: !isHome(team) ? team.homeTeamLogoUrl : team.awayTeamLogoUrl,
  });

  const remainingPredictions = () => {
    if (!predictions) return '0/8';
    const remain = Object.keys(predictions).filter(
      k => predictions[k] !== null
    );
    return `${remain.length}/8`;
  };

  useEffect(() => {
    if (currentContestExists) resetGame();
  }, [currentContestExists]);

  return (
    <Group specSelector="side-drawer-draft-contest" isFullWidth isVertical>
      <Group isFullWidth isVerticalCentered spaceSize={8}>
        <Group isGrown isVerticalCentered>
          <Icon>
            <TrophySolid />
          </Icon>
          <GeneralText>
            <b>Draft Contest</b>
          </GeneralText>
        </Group>
        <ImageWrapper source={NFLLogo} alt={'NFL Logo'} height={28} />
        {modality && teams && (
          <Badge isSecondary={modality === 'paid'} isSmall>
            <b>{modality.toUpperCase()}</b>
          </Badge>
        )}
      </Group>
      <Space isVertical size={5} />
      <Group
        isFullWidth
        isCentered
        isVertical
        padding={'10px 5px 7px'}
        style={{ backgroundColor: '#FFF', borderRadius: 4 }}>
        {teams && (
          <Group isVertical>
            <Group isVerticalCentered spaceSize={10}>
              <ImageWrapper
                source={homeTeam(teams[0]).logo}
                height={18}
                alt={homeTeam(teams[0]).name}
              />
              <GeneralText isSmall color={colors.tertiary}>
                <b>x</b>
              </GeneralText>
              <ImageWrapper
                source={awayTeam(teams[0]).logo}
                height={18}
                alt={awayTeam(teams[0]).name}
              />
              <Fill />
              <VerticalDivider height={20} />
              <Fill />
              <ImageWrapper
                source={homeTeam(teams[1]).logo}
                height={18}
                alt={homeTeam(teams[1]).name}
              />
              <GeneralText isSmall color={colors.tertiary}>
                <b>x</b>
              </GeneralText>
              <ImageWrapper
                source={awayTeam(teams[1]).logo}
                height={18}
                alt={awayTeam(teams[1]).name}
              />
            </Group>
          </Group>
        )}

        <Space isVertical size={5} />

        {teams && !currentContestExists ? (
          <Group isFullWidth isCentered isVerticalCentered spaceSize={6}>
            <GeneralText isSmall color={colors.tertiary}>
              <b>{friendlyNames(type)}</b>
            </GeneralText>
            <VerticalDivider height={10} />
            <GeneralText isSmall color={colors.tertiary}>
              {selectedEntryFee ? (
                <>
                  <b>${selectedEntryFee.entryFee}</b> Entry Fee
                </>
              ) : (
                '$0 Entry Fee'
              )}
            </GeneralText>
            <VerticalDivider height={10} />
            <GeneralText isSmall color={colors.tertiary}>
              Predictions <b>{remainingPredictions()}</b>
            </GeneralText>
          </Group>
        ) : (
          <>
            <GeneralText isSmall color={colors.tertiary}>
              <b>No draft contest</b>
            </GeneralText>
            <Space isVertical size={8} />
          </>
        )}
      </Group>

      {teams && (
        <Group isFullWidth>
          <Button
            specSelector="side-drawer-finish-contest"
            isXSmall
            isFullWidth
            onClick={() => {
              navigate('/gameplay');
              hideDrawer();
            }}
            style={{ marginTop: 10 }}>
            Finish contest
          </Button>
          <Button
            isXSmall
            isOutlined
            isSecondary
            onClick={() => {
              navigate('/gametype');
              hideDrawer();
              resetGame();
            }}
            style={{ marginTop: 10 }}>
            Clear draft
          </Button>
        </Group>
      )}
    </Group>
  );
};

CurrentContest.propTypes = {
  hideDrawer: PropTypes.func,
};

export default CurrentContest;
