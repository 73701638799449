import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import 'moment-timezone';

const OldTimeBlocks = ({ onChange }) => {
  const dates = value => {
    if (value == 0) return {};

    value = JSON.parse(value);

    const start = moment()
      .subtract(1, 'day')
      .isoWeekday(2)
      .startOf('day')
      [value[0]](value[1], 'week');

    const end = moment(start)
      .isoWeekday(1)
      .endOf('day')
      .add(1, 'week')
      .add(1, 'week');

    return { start: start.toDate(), end: end.toDate() };
  };
  return (
    // eslint-disable-next-line jsx-a11y/no-onchange
    <select
      style={{
        width: 'calc(100% - 30px)',
        border: '1px solid #CCC',
        margin: '15px 15px 0',
        padding: 10,
        fontSize: 12,
        backgroundColor: 'white',
      }}
      defaultValue={0}
      onChange={({ target }) => onChange(dates(target.value))}>
      <option value={JSON.stringify(['subtract', 4])}>Current -4</option>
      <option value={JSON.stringify(['subtract', 3])}>Current -3</option>
      <option value={JSON.stringify(['subtract', 2])}>Current -2</option>
      <option value={JSON.stringify(['subtract', 1])}>Current -1</option>
      <option value={0}>Current</option>
      <option value={JSON.stringify(['add', 1])}>Current +1</option>
    </select>
  );
};

OldTimeBlocks.propTypes = {
  onChange: PropTypes.func,
};

export default OldTimeBlocks;
