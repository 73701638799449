import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import PlayerCard from 'organisms/PlayerCard';

import Swiper from 'atoms/Swiper';
import Button from 'atoms/Button';
import Icon from 'atoms/Icon';
import GeneralText from 'atoms/GeneralText';

import CardWrapper from 'molecules/CardWrapper';

import { AngleLeft, AngleRight, ThumbsUp } from 'assets/icons';
import colors from 'assets/themes/colors';

import OverUnderMechanics from 'molecules/OverUnderMechanics';

const CardsSwiperOverUnder = ({
  onChangeCard,
  onClickLastCard,
  onSelectOption,
  data,
  currentIndex,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMoving, setMovingState] = useState(false);

  useEffect(() => {
    if (currentIndex != null && currentIndex != currentSlide)
      setCurrentSlide(currentIndex);
  }, [currentIndex]);

  const styles = {
    wrapper: {
      padding: '0 13px',
    },
    slideStyle: {
      padding: '5px 4px',
    },
  };

  return (
    <div
      data-guided-tour="card-swiper-over-under"
      style={{ width: '100%', position: 'relative' }}>
      <div>
        {currentSlide !== 0 && currentSlide !== data.length && (
          <Button
            isBare
            specSelector="cards-swiper-arrow-left"
            onClick={
              currentSlide > 0
                ? () => {
                    setMovingState(true);
                    setCurrentSlide(currentSlide - 1);
                  }
                : null
            }
            style={{
              position: 'absolute',
              opacity: isMoving ? 0.4 : 1,
              zIndex: 5,
              top: 150,
              left: 60,
            }}>
            <Icon size={46}>
              <AngleLeft />
            </Icon>
          </Button>
        )}

        {currentSlide !== data.length && (
          <Button
            data-guided-tour="arrow-right-over-under"
            specSelector="cards-swiper-arrow-right"
            isBare
            onClick={
              currentSlide < data.length
                ? () => {
                    setMovingState(true);
                    setCurrentSlide(currentSlide + 1);
                  }
                : null
            }
            style={{
              position: 'absolute',
              opacity: isMoving ? 0.4 : 1,
              zIndex: 5,
              top: 150,
              right: 60,
            }}>
            <Icon size={46}>
              <AngleRight />
            </Icon>
          </Button>
        )}

        <Swiper
          style={styles.wrapper}
          slideStyle={styles.slideStyle}
          index={currentSlide}
          onChangeIndex={current => {
            setCurrentSlide(current);
            setMovingState(true);
          }}
          onTransitionEnd={() => {
            setMovingState(false);
            onChangeCard(currentSlide, data[currentSlide]);
          }}>
          {data.map((cardProps, index) => (
            <PlayerCard
              {...cardProps}
              isActive={index === currentSlide && !isMoving}
              key={cardProps.id}>
              <OverUnderMechanics
                name={cardProps.id}
                stats={
                  cardProps.projections.overunder.filter(
                    m => m.name === cardProps.stats.name
                  )[0]
                }
                selected={cardProps.selected}
                onChange={(name, isSelected) => {
                  try {
                    const split = name.split('-');
                    const option = split[1];
                    const cardId = split[0];
                    onSelectOption({
                      cardId,
                      selection: isSelected ? option : null,
                    });
                  } catch (error) {
                    console.error('An error occurred in CardSwiper: ' + error);
                  }
                }}
              />
            </PlayerCard>
          ))}

          <CardWrapper
            topLabel={<GeneralText color={colors.white}>&nbsp;</GeneralText>}
            sx={{ paddingBottom: 25, height: 460 }}>
            <div
              style={{
                height: 400,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}>
              <Icon size={100} color={colors.secondary}>
                <ThumbsUp />
              </Icon>
              <GeneralText
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  textAlign: 'center',
                }}>
                <b>
                  Finished making your predictions?
                  <br />
                  Submit your entry now!
                </b>
              </GeneralText>
              {!!onClickLastCard && (
                <Button isSmall onClick={onClickLastCard}>
                  SUBMIT
                </Button>
              )}
            </div>
          </CardWrapper>
        </Swiper>
      </div>
    </div>
  );
};

CardsSwiperOverUnder.defaultProps = {
  data: [],
  onChangeCard: () => {},
  onSelectOption: () => {},
};

CardsSwiperOverUnder.propTypes = {
  currentIndex: PropTypes.number,
  data: PropTypes.array,
  onChangeCard: PropTypes.func,
  onClickLastCard: PropTypes.func,
  onSelectOption: PropTypes.func,
};

export default CardsSwiperOverUnder;
