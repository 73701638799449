import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import TimeBlockSelector from 'molecules/TimeBlockSelector';

import useAnalytics from 'hooks/useAnalytics';

const DayDivisor = ({ onChange, defaultSelected, availableTeams }) => {
  const { logEvent } = useAnalytics();

  useEffect(() => {
    if (defaultSelected) {
      logEvent('day_divisor', { item_name: defaultSelected });
    }
  }, [defaultSelected]);

  const items = [
    {
      label: 'Playoff Saturday',
      value: 'Early',
      size: ((availableTeams || {})['Early'] || []).length,
    },
    {
      label: 'Playoff Sunday',
      value: 'Afternoon',
      size: ((availableTeams || {})['Afternoon'] || []).length,
    },
    // {
    //   label: 'Sunday',
    //   value: 'Late',
    //   size: ((availableTeams || {})['Late'] || []).length,
    // },
  ];

  return (
    <div
      data-guided-tour="day-divisor-select"
      style={{
        width: '100%',
        maxWidth: 445,
        margin: 'auto',
        display: 'flex',
        padding: '20px 15px 10px',
      }}>
      <TimeBlockSelector
        initialSelected={defaultSelected}
        onChange={selection => {
          logEvent('day_divisor', { item_name: selection });
          onChange(selection);
        }}
        items={items}
      />
    </div>
  );
};

DayDivisor.propTypes = {
  availableTeams: PropTypes.object,
  defaultSelected: PropTypes.string,
  onChange: PropTypes.func,
};

export default DayDivisor;
