import _invertColor from 'invert-color';

export default {
  //Used for component colors that can change to any color
  primary: 'rgba(43, 48, 61, 1)',
  secondary: 'rgba(216, 216, 216, 1)',
  tertiary: 'rgba(74, 74, 74, 1)',
  quaternary: 'rgba(204, 204, 204, 1)',
  accent: 'rgba(64, 187, 239, 1)',
  accentHover: 'rgba(64, 187, 239, 0.7)',
  alert: 'rgba(255, 90, 90, 1)',
  positive: 'rgba(45, 204, 112, 1)',
  //used for colors that will represent name, e.g. white will always be some constiation of white
  white: 'rgba(255, 255, 255, 1)',
  black: 'rgba(0, 0, 0, 1)',
  lightGray: 'rgba(243, 243, 243, 1)',
  mattedGray: 'rgba(136, 136, 136, 1)',
  freshGreen: 'rgba(134, 205, 88, 1)',
  attention: '#FFC400',
};

const invertColorConfig = {
  black: '#202b33',
  white: '#FFFFFF',
  threshold: 0.8,
};

const RGBPartToHEX = rgb => {
  let hex = Number(rgb).toString(16);
  if (hex.length < 2) hex = '0' + hex;
  return hex;
};

const fullRGBToHEX = (r, g, b) => {
  const red = RGBPartToHEX(r);
  const green = RGBPartToHEX(g);
  const blue = RGBPartToHEX(b);
  return `#${red + green + blue}`;
};

const getRGB = string => {
  const match = string.match(
    /rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/
  );
  return match ? [match[1], match[2], match[3]] : [];
};

export const invertColor = (color, config) => {
  const isHEX = /^#[0-9A-F]{6}$/i.test(color);
  if (!isHEX) color = fullRGBToHEX(...getRGB(color));
  return _invertColor(color, config || invertColorConfig);
};

export const convertHexToRGB = (hex, opacity, returnAsArray) => {
  hex = hex.replace('#', '');
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);
  let result = `rgb(${r}, ${g}, ${b})`;
  if (typeof opacity === 'number')
    result = `rgba(${r}, ${g}, ${b}, ${opacity})`;
  if (returnAsArray) result = [r, g, b];
  return result;
};

export const convertRGBToHSL = rgb => {
  const r = rgb[0] / 255;
  const g = rgb[1] / 255;
  const b = rgb[2] / 255;
  const min = Math.min(r, g, b);
  const max = Math.max(r, g, b);
  const delta = max - min;
  let h;
  let s;
  if (max === min) {
    h = 0;
  } else if (r === max) {
    h = (g - b) / delta;
  } else if (g === max) {
    h = 2 + (b - r) / delta;
  } else if (b === max) {
    h = 4 + (r - g) / delta;
  }
  h = Math.min(h * 60, 360);
  if (h < 0) h += 360;
  const l = (min + max) / 2;
  if (max === min) {
    s = 0;
  } else if (l <= 0.5) {
    s = delta / (max + min);
  } else {
    s = delta / (2 - max - min);
  }
  return [h, s * 100, l * 100];
};

export const darken = (color, ratio) => {
  let hsl = convertRGBToHSL(convertHexToRGB(color, null, true));
  hsl[2] -= hsl[2] * ratio;
  return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

export const lighten = (color, ratio) => {
  let hsl = convertRGBToHSL(convertHexToRGB(color, null, true));
  hsl[2] += hsl[2] * ratio;
  return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};
