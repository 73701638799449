import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { navigate } from '@reach/router';

import { Button, Space } from 'atoms';

import Modal from 'molecules/Modal';
import CardsSummary from 'organisms/CardsSummary';
import ModalHeader from 'organisms/ModalHeader';

import { groupBy } from 'services/utilities';

import colors from 'assets/themes/colors';

import EntryFees from './EntryFees';

import useAnalytics from 'hooks/useAnalytics';

import useGuidedTour from 'hooks/guidedTour';
import { gamePlayReviewAndSubmitTour } from 'hooks/guidedTour/tours';

const Summary = ({ showSummary, data, onClickToClose, onClickEdit }) => {
  const [Tour] = useGuidedTour({
    autoStart: true,
    tourName: gamePlayReviewAndSubmitTour.name,
  });

  const {
    competitors,
    predictions,
    entryFees,
    selectedEntryFee,
    onChangeEntryFee,
    gameType,
  } = data;

  const { logEvent } = useAnalytics();

  useEffect(() => {
    logEvent('contest_review');
  }, []);

  const teamGroups = groupBy(competitors, 'teamName');

  const teams = Object.keys(teamGroups).map((key, index) => (
    <Fragment key={key}>
      <CardsSummary
        specSelector={`contest-summary-team-${index + 1}`}
        onClickEdit={onClickEdit}
        data={teamGroups[key].map(item => ({
          ...item,
          gameType,
          selected: predictions[item.id],
        }))}
        teamNumber={index + 1}
      />
      {index < 1 && <Space isLarge isVertical />}
    </Fragment>
  ));

  return (
    <>
      <Tour
        steps={gamePlayReviewAndSubmitTour.steps}
        continuous
        showSkipButton
        variants={[
          {
            steps: gamePlayReviewAndSubmitTour.variant,
            disableScrolling: true,
          },
        ]}
      />

      <Modal
        specSelector="modal-contest-summary"
        isOpen={showSummary}
        customHeader={() => (
          <ModalHeader
            onClickToClose={onClickToClose}
            title="Become a Winner"
          />
        )}
        onClickToClose={onClickToClose}>
        <div style={{ width: '100%', maxWidth: 450, margin: 'auto' }}>
          <div
            style={{
              backgroundColor: colors.lightGray,
              margin: '-15px -15px 0',
              paddingBottom: 5,
            }}>
            <EntryFees
              data={entryFees}
              selected={selectedEntryFee}
              onSelect={onChangeEntryFee}
              hideProgressBar
            />
          </div>

          <Space isMedium isVertical />

          {teams}

          <Space isLarge isVertical />

          <Button
            data-guided-tour="button-gameplay-summary"
            specSelector="gameplay-summary-submit"
            isFullWidth
            onClick={() => navigate('/submit-gate')}>
            SUBMIT
          </Button>

          <Space isVertical size={10} />
        </div>
      </Modal>
    </>
  );
};

Summary.propTypes = {
  data: PropTypes.object,
  onClickEdit: PropTypes.func,
  onClickToClose: PropTypes.func,
  showSummary: PropTypes.bool,
};

export default Summary;
