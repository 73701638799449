import React from 'react';
import PropTypes from 'prop-types';

const Filler = props => {
  return (
    <div
      css={theme => ({
        background: theme.colors.accent,
        border: `1px solid ${theme.colors.white}`,
        borderRadius: 'inherit',
        height: '100%',
        transition: 'width .2s ease-in',
        maxWidth: '100%',
        width: `${props.percentage}%`,
      })}></div>
  );
};

const ProgressBar = props => {
  return (
    <div
      css={theme => ({
        backgroundColor: `${theme.colors.white}`,
        borderRadius: '4px',
        position: 'relative',
        height: '6px',
        width: `${props.width}%`,
      })}>
      <Filler percentage={props.percentage} />
    </div>
  );
};

Filler.propTypes = {
  percentage: PropTypes.number,
};

Filler.defaultProps = {
  percentage: 0,
};

ProgressBar.propTypes = {
  percentage: PropTypes.number,
  width: PropTypes.number,
};

ProgressBar.defaultProps = {
  percentage: 0,
  width: 358,
};

export default ProgressBar;
