import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/core';

import { fadeInRight } from 'react-animations';

import colors from 'assets/themes/colors';

// TODO: Update to use react-spring and animate when hide too;

const enterRightAnimation = keyframes`${fadeInRight}`;

const Wrapper = styled.div`
  position: fixed;
  z-index: 7;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

const Outside = styled.div`
  position: absolute;
  z-index: 8;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  ${({ maxWidth }) => css`
    position: absolute;
    z-index: 9;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: ${maxWidth || 320}px;
    background-color: ${colors.lightGray};
    animation: 0.4s ${enterRightAnimation};
    box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.3);
  `}
`;

const Content = styled.div`
  overflow: auto;
  flex: 1;
`;

const Drawer = props => {
  const { children, onClickToHide, onHide, isOpen, maxWidth } = props;

  if (!isOpen) return null;

  const handleHide = () => {
    onHide();
    onClickToHide();
  };

  return (
    <Wrapper>
      <Container {...{ maxWidth }}>
        <Content>{children({ isOpen, hideDrawer: handleHide })}</Content>
      </Container>
      <Outside onClick={handleHide} />
    </Wrapper>
  );
};

Drawer.defaultProps = {
  children: () => {},
};

Drawer.propTypes = {
  children: PropTypes.func,
  isOpen: PropTypes.bool,
  maxWidth: PropTypes.number,
  onClickToHide: PropTypes.func,
  onHide: PropTypes.func,
};

export default Drawer;
