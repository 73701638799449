import React from 'react';

import { navigate } from '@reach/router';

import Wrapper from './Wrapper';

import colors from 'assets/themes/colors';
import { Button, Group, GeneralText } from 'atoms';

const NothingToDoHere = () => {
  return (
    <Wrapper>
      <Group
        isVertical
        isCentered
        isVerticalCentered
        padding={20}
        spaceSize={60}
        style={{
          width: '100%',
          maxWidth: 450,
          margin: 'auto',
        }}>
        <GeneralText color={colors.white} isLarge>
          <b>Hello there!</b>
        </GeneralText>
        <GeneralText color={colors.white} isLarge>
          <b>
            You have nothing to do here,
            <br />
            go back to the game.
          </b>
        </GeneralText>
      </Group>
      <div
        style={{
          width: '100%',
          padding: '15px 15px 15px',
          backgroundColor: 'white',
        }}>
        <Button
          isFullWidth
          style={{ maxWidth: 450, margin: 'auto' }}
          onClick={() => navigate('/teams')}>
          GO BACK
        </Button>
      </div>
    </Wrapper>
  );
};

export default NothingToDoHere;
