import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import * as actions from './actions';

import { MainContext } from 'containers/MainContainer';
import useGameSteps from 'hooks/useGameSteps';

import { gamePlayTour } from 'hooks/guidedTour/tours';
import useGuidedTour from 'hooks/guidedTour';

import { navigate } from '@reach/router';

// Components
import Wrapper from './components/Wrapper';
import EntryFees from './components/EntryFees';
import OverUnder from './components/OverUnder';
import Statpicks from './components/Statpicks';
import Summary from './components/Summary';

import { Button } from 'atoms';

import { toggleGameType, findEmptyPrediction } from './helpers';
import useAnalytics from 'hooks/useAnalytics';

export const Gameplay = ({
  fetchEntryFees,
  entryFees,
  selectEntryFee,
  selectedEntryFee,
  gameType,
  modality,
  changeGameType,
  predictions,
  resetPredictions,
  timeBlockId,
  competitors,
  updateGame,
  isLoading,
}) => {
  const { isOnline } = useContext(MainContext);

  const [currentPlayer, setCurrentPlayer] = useState({});
  const [showSummary, setSummaryState] = useState(false);

  const { isReadyByValidator } = useGameSteps(['predictions']);

  const { logEvent } = useAnalytics();

  const [Tour] = useGuidedTour({
    autoStart: true,
    tourName: gamePlayTour.name,
  });

  useEffect(() => {
    if (isOnline)
      fetchEntryFees(timeBlockId, gameType == 'overunder' ? 1 : 2, modality);
    logEvent('gametype', {
      item_name: gameType === 'statpicks' ? 'Statpicks' : 'Over/Under',
    });
  }, [gameType]);

  if (!useGameSteps(['gameType', 'competitors']).isReadyByValidator) {
    navigate('/teams');
    return null;
  }

  const mechanicsProps = {
    competitors,
    currentPlayer,
    predictions,
    onChangeCard: setCurrentPlayer,
    onSelectPrediction: updateGame,
    onClickLastCard: () => {
      if (isReadyByValidator) {
        setSummaryState(true);
      } else {
        findEmptyPrediction(predictions, competitors, setCurrentPlayer);
      }
    },
  };

  const remainingPredictions = () => {
    if (!predictions) return '0/8';
    const remain = Object.keys(predictions).filter(
      k => predictions[k] !== null
    );
    return `${remain.length}/8`;
  };

  return (
    <>
      {!showSummary && (
        <Tour
          steps={gamePlayTour.steps}
          continuous
          showSkipButton
          disableScrolling
          variants={[
            {
              steps: gamePlayTour.variant,
              disableScrolling: true,
            },
          ]}
        />
      )}

      <Wrapper
        isLoading={isLoading}
        gameType={gameType}
        onChangeGameType={() => {
          resetPredictions();
          changeGameType(toggleGameType[gameType]);
        }}>
        <EntryFees
          data={entryFees}
          selected={selectedEntryFee || entryFees[0]}
          onSelect={selectEntryFee}
        />

        {gameType === 'overunder' ? (
          <OverUnder {...mechanicsProps} />
        ) : (
          <Statpicks {...mechanicsProps} />
        )}

        {showSummary && (
          <Summary
            showSummary={showSummary}
            data={{
              gameType,
              competitors,
              predictions,
              entryFees,
              selectedEntryFee,
              onChangeEntryFee: selectEntryFee,
            }}
            onClickEdit={playerData => {
              setCurrentPlayer(playerData);
              setSummaryState(false);
            }}
            onClickToClose={() => setSummaryState(false)}
          />
        )}

        <div
          style={{
            width: '100%',
            padding: '20px 15px',
            backgroundColor: 'rgba(243,243,243,1)',
            position: isReadyByValidator ? 'sticky' : 'static',
            bottom: 0,
            zIndex: 6,
          }}>
          <Button
            specSelector="gameplay-submit-contest"
            isFullWidth
            onClick={() => {
              if (isReadyByValidator) {
                setSummaryState(true);
              } else {
                findEmptyPrediction(predictions, competitors, setCurrentPlayer);
              }
            }}>
            REVIEW AND SUBMIT
            {remainingPredictions() === '0/8' ? (
              ''
            ) : (
              <> ({remainingPredictions()})</>
            )}
          </Button>
        </div>
      </Wrapper>
    </>
  );
};

Gameplay.defaultProps = {
  predictions: {},
  competitors: [],
  entryFees: [],
};

Gameplay.propTypes = {
  changeGameType: PropTypes.func,
  competitors: PropTypes.array,
  entryFees: PropTypes.array,
  fetchEntryFees: PropTypes.func,
  gameType: PropTypes.string,
  isLoading: PropTypes.bool,
  modality: PropTypes.string,
  predictions: PropTypes.object,
  resetPredictions: PropTypes.func,
  selectEntryFee: PropTypes.func,
  selectedEntryFee: PropTypes.object,
  timeBlockId: PropTypes.string,
  updateGame: PropTypes.func,
};

export default connect(
  store => ({
    ...store.teamsSelection,
    ...store.gameplay,
    gameType: store.gameType.type,
    modality: store.gameType.modality,
  }),
  { ...actions }
)(Gameplay);
