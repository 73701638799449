import React from 'react';

export default {
  name: 'gamePlayReviewAndSubmitTour',
  steps: [
    {
      target: 'body',
      title: 'Game Play Summary',
      content:
        "Now that you've finished making your predictions, you can review them one more time on this summary screen before submitting your entry.",
      placement: 'center',
    },
    {
      // target: '[data-guided-tour="inline-player-card-edit"]',
      title: 'Modifying Your Prediction',
      target: 'body',
      content:
        'Oops! See something you want to change? Not a problem - Click on the pencil and you can go back to the Player Card to make a change.',
      placement: 'center',
    },
    {
      target: '[data-guided-tour="inline-player-card-backup"]',
      title: 'Backup Players',
      content:
        'Remember: your backup player prediction will only count if one of your starting players does not play for some reason, such as injury. ',
    },
    {
      target: '[data-guided-tour="button-gameplay-summary"]',
      title: "You've Completed the Tour",
      content:
        "After you're done reviewing one last time - click SUBMIT! Then come back to see how you did. Good Luck!",
    },
  ],
  variant: [
    {
      target: '[data-guided-tour="inline-player-card"]',
      content: (
        <>
          Oops! See something you want to change? Not a problem - Click on the{' '}
          <b>pencil</b> and you can go back to the Player Card to make a change.
        </>
      ),
      placement: 'auto',
      placementBeacon: 'right',
    },
  ],
};
